import React, { useState, useEffect, useRef } from 'react';
import { useMutation, gql, useApolloClient } from '@apollo/client';
import { useUsers } from '../hooks/useUsers'; // Import the custom hook
import { clickSearchBox, closeResultBox } from '../utils/searchUtils'; // Import the functions

export const FOLLOW_USER = gql`
  mutation FollowUser($followingId: ID!) {
    followUser(followingId: $followingId) {
      id
      followingId
      followerId
      createdAt
    }
  }
`;

export const UNFOLLOW_USER = gql`
  mutation UnfollowUser($followingId: ID!) {
    unfollowUser(followingId: $followingId) {
      id
      followingId
      followerId
      deletedAt
    }
  }
`;

export const IS_FOLLOWING = gql`
  query IsFollowing($followingId: ID!) {
    isFollowing(followingId: $followingId)
    }
`;

const FullSearchBar = () => {
  const searchBoxRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [followButtonText, setFollowButtonText] = useState({});
  const [isResultBoxVisible, setIsResultBoxVisible] = useState(false);
  const { loading, users, error } = useUsers(); // Use the custom hook
  const client = useApolloClient();

  // Set up the follow user mutation
  const [followUser, { loading: followLoading, error: followError }] = useMutation(FOLLOW_USER, {
    context: {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`, // Include token in headers
      },
    },
  });

  // Set up the unfollow user mutation
  const [unfollowUser] = useMutation(UNFOLLOW_USER, {
    context: {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`, // Include token in headers
      },
    },
  });

  // Check if the user is following a specific user
  const checkFollowingStatus = async (userId) => {
    try {
      const { data } = await client.query({
        query: IS_FOLLOWING,
        variables: { followingId: userId },
        context: {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`, // Include token in headers
          },
        },
      });
      return data.isFollowing;
    } catch (error) {
      console.error('Error checking following status:', error.message);
      return false;
    }
  };

  useEffect(() => {
    const initializeFollowButtonText = async () => {
      const newFollowButtonText = {};
      for (const user of users) {
        const isFollowing = await checkFollowingStatus(user.id);
        newFollowButtonText[user.id] = isFollowing;
      }
      setFollowButtonText(newFollowButtonText);
    };

    if (users.length > 0) {
      initializeFollowButtonText();
    }
  }, [users, client]);

  useEffect(() => {
    if (users) {
      const results = users.filter(user =>
        user.username.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredUsers(results);
    }
  }, [users, searchTerm]);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
    setIsResultBoxVisible(event.target.value.length > 0);
  };

  const handleClickOutside = (event) => {
    if (
      searchBoxRef.current &&
      !searchBoxRef.current.contains(event.target)
    ) {
      setIsResultBoxVisible(false);
    }
  };

  const handleFollowUnfollow = async (userId, event) => {
    event.preventDefault(); 
    const isFollowing = followButtonText[userId];
    
    try {
      if (isFollowing) {
        // Execute unfollow mutation
        const { data } = await unfollowUser({ variables: { followingId: userId } });
        console.log('Unfollow successful:', data.unfollowUser);
        
        // Update the state to reflect that the user is now not followed
        setFollowButtonText(prev => ({
          ...prev,
          [userId]: false
        }));
      } else {
        // Execute follow mutation
        const { data } = await followUser({ variables: { followingId: userId } });
        console.log('Follow successful:', data.followUser);
        
        // Update the state to reflect that the user is now followed
        setFollowButtonText(prev => ({
          ...prev,
          [userId]: true
        }));
      }
    } catch (error) {
      console.error('Follow/Unfollow error:', error.message);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (loading) return <div></div>;
  if (error) return <div></div>;

  return (
    <div className="search-box-container">
          <div className="contents">
            <hth-search-large className="nd">
              <div className="hth-search-bar">
                <div>
                  <form className="hth-search-form" autoComplete="off" role="search" style={{ overflow: 'visible' }}>
                    <hth-search-input className="search-input" style={{ width: '540px' }} type="search" placeholder="">
                      <label style={{ '--left-label-position': '0px' }}>
                        <div className="input-shell">
                          <span id="icon-container">
                            <svg role="img" id="search-icon" height="10" width="10" aria-hidden="true" viewBox="0 0 16 16">
                              <path d="M7 1.75a5.25 5.25 0 1 0 0 10.5 5.25 5.25 0 0 0 0-10.5zM.25 7a6.75 6.75 0 1 1 12.096 4.12l3.184 3.185a.75.75 0 1 1-1.06 1.06L11.304 12.2A6.75 6.75 0 0 1 .25 7z"></path>
                            </svg>
                          </span>
                          <span className="input-container">
                            <input
                              id="search-box"
                              type="text"
                              autoComplete="off"
                              placeholder="Search"
                              value={searchTerm}
                              onChange={handleChange}
                              onFocus={() => setIsResultBoxVisible(searchTerm.length > 0)}
                            />
                            {isResultBoxVisible && (
                              <div id="typeaheadDropdown" className="result-box">
                                {filteredUsers.length > 0 ? (
                                  filteredUsers.map(user => (
                                    <div key={user.id} className="result-item">
                                      <img
                                        className="result-item-pic"
                                        src={user.profilePic}
                                        alt={user.username}
                                      />
                                      <span>{user.username}</span>

                                      <button
                                    onClick={(e) => handleFollowUnfollow(user.id, e)}
                                  >
                                    {followButtonText[user.id] ? 'Unfollow' : 'Follow'}
                                  </button>
                                    </div>
                                  ))
                                ) : (
                                  <div className="no-results">No results found</div>
                                )}
                              </div>
                            )}
                          </span>
                        </div>
                      </label>
                    </hth-search-input>
                  </form>
                </div>
              </div>
            </hth-search-large>
          </div>
    </div>
  );
};

export default FullSearchBar;