import React from 'react';
import { Link } from 'react-router-dom';
import NewsSection from './SideBarNews';
import SidebarProfile from './SideBarProfile';
import SideBarMenu from './SideBarMenu';
import '../css/sidebar.css';
import OnlineFriend from './OnlineFriends';

const Sidebar = ({ onOpenCreateGame, onOpenFriendlyGame, onToggleMessageTab, isCollapsed }) => {
  return (
    <div className="lft-shell visible">
      <div className="lft-shell-abs">
        <div className="sidebar">
          <div className="sidebar-2">
            <div className="sidebar-border">
              <div className={`sidebar-padding ${isCollapsed ? 'w-72' : 'w-244'}`}>
              <div id="start" className="styles-scope hth-masthead">
              <icon-button id="guide-button" className="style-scope hth-masthead">
                <button id="button" className="style-scope hth-icon-button">
                  <hth-icon id="guide-icon" className="style-scope hth-masthead">
                    <hth-icon-shape className="style-scope hth-masthead">
                      <icon-shape className="hth-spec-icon-shape">
                        <div style={{ width: '100%', height: '100%', fill: 'rgb(255, 255, 255)' }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24"
                            viewBox="0 0 24 24"
                            width="24"
                            focusable="false"
                            style={{ pointerEvents: 'none', display: 'block', width: '100%', height: '100%' }}
                          >
                            <path d="M21 6H3V5h18v1zm0 5H3v1h18v-1zm0 6H3v1h18v-1z"></path>
                          </svg>
                        </div>
                      </icon-shape>
                    </hth-icon-shape>
                  </hth-icon>
                </button>
              </icon-button>
              <hth-top-logo id="logo" className="style-scope hth-masthead">
                <div id="logo" className="hth-simple-endpoint style-scope hth-top-logo">
                  <div className="style-scope hth-top-logo">
                    <hth-logo className="style-scope hth-top-logo">
                      <hth-icon id="logo-icon" className="style-scope hth-logo">
                        <hth-icon-shape className="style-scope yt-icon">
                          <icon-shape className="hth-spec-icon-shape">
                            <div className="sprite" style={{ width: '100%', height: '100%', fill: 'currentcolor' }}>
                              <div className="home-header-logo"></div>
                            </div>
                          </icon-shape>
                        </hth-icon-shape>
                      </hth-icon>
                    </hth-logo>
                  </div>
                </div>
              </hth-top-logo>
            </div>
                <div className="sidebar-nav-icon">
                  <SideBarMenu onOpenCreateGame={onOpenCreateGame} onToggleMessageTab={onToggleMessageTab} />
                </div>
                <NewsSection isCollapsed={isCollapsed}/>
                <OnlineFriend onOpenFriendlyGame={onOpenFriendlyGame}/>
                <SidebarProfile isCollapsed={isCollapsed} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;