import React from "react";

const TermsOfConditions = () => {
    return(
        <div>
            <header style={{ height: '60px', width: '100%', background: 'rgb(172 255 195)'}}>

            </header>
            <div style={{ padding: '1rem', color: '#fff', width: '1170px', marginLeft: 'auto', marginRight: 'auto' }}>
                <div style={{ marginBottom: '2rem' }}>
                    <h1 style={{ fontSize: '2rem' }}>Terms of Conditions</h1>
                    <div style={{ fontSize: '12px'}}>Last Update Posted: March 25th, 2025</div>
                </div>
                <div style={{ gap: '1rem', display: 'flex', flexDirection: 'column' }}>
                    <div>
                        <div style={{ fontSize: '1.5rem' }}>1. Eligibility</div>
                        <div style={{ fontSize: '13px'}}>
                        You must be at least 18 years old (or legal age in your jurisdiction) to participate in wager-based games. By using GHChess, you represent and warrant that:
                        </div>
                        <div style={{ fontSize: '13px'}}>
                        •	You are legally allowed to participate in skill-based wagering.
                        </div>
                        <div style={{ fontSize: '13px'}}>
                        •	You are not prohibited by any law, regulation, or authority from using our services.
                        </div>
                    </div>

                    <div>
                        <div style={{ fontSize: '1.5rem' }}>2. Account Registration</div>
                        <div style={{ fontSize: '13px'}}>
                        •	You must create an account with accurate information.
                        </div>
                        <div style={{ fontSize: '13px'}}>
                        •	You are responsible for safeguarding your login credentials.
                        </div>
                        <div style={{ fontSize: '13px'}}>
                        •	We reserve the right to suspend or terminate accounts at our discretion, especially for fraudulent activity or violations of these terms.
                        </div>
                    </div>
                    <div>
                        <div style={{ fontSize: '1.5rem' }}>3. Wagering & Payments</div>
                        <div style={{ fontSize: '13px'}}>
                        •	All games involve skill-based competition and real-money wagers.
                        </div>
                        <div style={{ fontSize: '13px'}}>
                        •	Funds deposited into your GHChess account can be used for games, tournaments, and other platform features.
                        </div>
                        <div style={{ fontSize: '13px'}}>
                        •	Winnings are subject to withdrawal policies, including possible verification checks.
                         </div>
                        <div style={{ fontSize: '13px'}}>
                        •	All transactions are final. GHChess is not liable for losses resulting from user error or gameplay outcomes.
                        </div>
                    </div>
                    <div>
                        <div style={{ fontSize: '1.5rem' }}>4. Fair Play & Anti-Cheating Policy</div>
                        <div style={{ fontSize: '13px'}}>
                        Cheating is strictly prohibited. This includes, but is not limited to:</div>
                        <div style={{ fontSize: '13px'}}>
                        •	Using chess engines, bots, or external assistance during gameplay.
                        </div>
                        <div style={{ fontSize: '13px'}}>
                        •	Colluding with other players.
                        </div>
                        <div style={{ fontSize: '13px'}}>
                        •	Exploiting bugs or manipulating game outcomes.
                        </div>
                        <div style={{ fontSize: '13px'}}>
                        Detection & Enforcement:
                        </div>
                        <div style={{ fontSize: '13px'}}>
                        •	GHChess uses advanced cheat detection systems and manual review processes.
                        </div>
                        <div style={{ fontSize: '13px'}}>
                        •	If you are found cheating:
                        </div>
                        <div style={{ fontSize: '13px'}}>
                        o	Your account may be banned permanently.
                        </div>
                        <div style={{ fontSize: '13px'}}>
                        o	Winnings may be forfeited.
                        </div>
                        <div style={{ fontSize: '13px'}}>
                        o	Legal action may be pursued in severe cases.
                        </div>           
                    </div>
                    <div>
                        <div style={{ fontSize: '1.5rem' }}>5. Skill-Based Play</div>
                        
                        <div style={{ fontSize: '13px'}}>
                        GHChess hosts skill-based contests. Outcomes are determined solely by player ability and strategy. We do not offer chance-based gambling.
                        </div>
                    </div>
                    <div>
                        <div style={{ fontSize: '1.5rem' }}>6. User Conduct</div>
                        
                        <div style={{ fontSize: '13px'}}>
                        You agree to:
                        </div>
                        <div style={{ fontSize: '13px'}}>
                        •	Behave respectfully towards other users.
                        </div>
                        <div style={{ fontSize: '13px'}}>
                        •	Avoid abusive, harassing, or discriminatory language.
                        </div>
                        <div style={{ fontSize: '13px'}}>
                        •	Not engage in spam, advertising, or unauthorized promotion.
                        </div>
                        <div style={{ fontSize: '13px'}}>
                        GHChess reserves the right to moderate chats and communications, and to suspend users who violate community standards.
                        </div>
                    </div>
                    <div>
                        <div style={{ fontSize: '1.5rem' }}>7. Intellectual Property</div>
                        
                        <div style={{ fontSize: '13px'}}>
                        All content, branding, and software are the property of GHChess and may not be copied, modified, or redistributed without permission.
                        </div>
                        <div style={{ fontSize: '13px'}}>
                        Users may not:
                        </div>
                        <div style={{ fontSize: '13px'}}>
                        •	Record, stream, or distribute gameplay without authorization.
                        </div>
                        <div style={{ fontSize: '13px'}}>
                        •	Misuse our brand or impersonate GHChess staff.
                        </div>
                    </div>
                    <div>
                        <div style={{ fontSize: '1.5rem' }}>8. Limitation of Liability</div>
                        
                        <div style={{ fontSize: '13px'}}>
                        GHChess is not liable for:
                        </div>
                        <div style={{ fontSize: '13px'}}>
                        •	Technical issues, delays, or server outages.
                        </div>
                        <div style={{ fontSize: '13px'}}>
                        •	User errors during gameplay.
                        </div>
                        <div style={{ fontSize: '13px'}}>
                        •	Loss of data, wagers, or earnings resulting from unauthorized access.
                        </div>
                        <div style={{ fontSize: '13px'}}>
                        Our platform is provided "as is" without warranties of any kind.
                        </div>
                    </div>
                    <div>
                        <div style={{ fontSize: '1.5rem' }}>9. Termination</div>
                        
                        <div style={{ fontSize: '13px'}}>
                        We may suspend or terminate your access to GHChess at any time, for any reason, including violation of these terms.
                        </div>
                    </div>
                    <div>
                        <div style={{ fontSize: '1.5rem' }}>10. Changes to Terms</div>
                        
                        <div style={{ fontSize: '13px'}}>
                        GHChess may update these Terms at any time. Continued use of the platform indicates acceptance of the revised Terms.
                        </div>
                    </div>
                    <div>
                        <div style={{ fontSize: '1.5rem' }}>11. Governing Law</div>
                        
                        <div style={{ fontSize: '13px'}}>
                        These Terms are governed by and construed in accordance with the laws of the applicable jurisdiction of GHChess operations.
                        </div>
                    </div>
                    <div>
                        <div style={{ fontSize: '1.5rem' }}>12. Contact Us</div>
                        
                        <div style={{ fontSize: '13px'}}>
                        For questions or concerns, contact:
                        </div>
                        <div style={{ fontSize: '13px'}}>
                        📧 support@grandhustler.com
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermsOfConditions