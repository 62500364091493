import React, { useState } from 'react';
import WalletDepositPage from './WalletDepositPage'; // Import the deposit page
import WalletWithdrawalPage from './WalletWithdrawalPage';
import '../css/wallet.css';

const Wallet = ({ doubloons, closeModal }) => {
  const [showDepositPage, setShowDepositPage] = useState(false); // State to toggle deposit page
  const [showWithdrawalPage, setShowWithdrawalPage] = useState(false); // State to toggle withdrawal page

  const handleDepositClick = () => {
    setShowDepositPage(true); 
    setShowWithdrawalPage(false); // Hide withdrawal page
  };

  const handleWithdrawClick = () => {
    setShowWithdrawalPage(true); // Show withdrawal page when withdraw button is clicked
    setShowDepositPage(false); // Hide deposit page
  };

  const goBack = () => {
    setShowDepositPage(false); // Go back to the wallet view from deposit page
    setShowWithdrawalPage(false); // Go back to the wallet view from withdrawal page
  };

  return (
    <div className="modal samri-siyed">
      <div className="overlay" onClick={closeModal}></div>
      <div className="card samri-siyed">
        {showDepositPage ? (
          <WalletDepositPage goBack={goBack} />
        ) : showWithdrawalPage ? (
          <WalletWithdrawalPage goBack={goBack} /> 
        ) : (
          <>
            <div className="flex header">
              <div>
                <svg fill="currentColor" viewBox="0 0 64 64" width="16px" className="svg-icon">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M45.71 13.349v.024c0 4.316-2.076 8.146-5.32 10.57H24.61l-.034-.024a13.19 13.19 0 0 1-5.286-10.57c0-7.296 5.914-13.21 13.21-13.21 7.296 0 13.21 5.914 13.21 13.21Z"
                  ></path>
                </svg>
                Wallet
              </div>
              <button type="button" className="x-button" onClick={closeModal}>
                <svg fill="currentColor" viewBox="0 0 64 64" width="16px" className="svg-icon">
                  <path d="m54.827 16.187-7.014-7.014L32 24.987 16.187 9.173l-7.014 7.014L24.987 32 9.173 47.813l7.014 7.014L32 39.013l15.813 15.814 7.014-7.014L39.013 32l15.814-15.813Z"></path>
                </svg>
              </button>
            </div>
            <div className="content scroll">
              <div className="modal-content">
                <div className="content-padding">
                  <div className="container">
                    <div className="flex modal-space">
                      <div className="flex gap column">
                        <div className="currency">
                          <div className="currency-header">
                            <span>Your currency</span>
                            <span>Value</span>
                          </div>
                          <div className="doubloon">
                            <div className="doubloon-container">
                              <div className="doubloon-details">
                              <svg
    height="45px"
    width="45px"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    fill="#000000"
  >
    <g>
      <path
        style={{ fill: "#D23ACB" }}
        d="M256,8.17L179.745,256L256,503.83c136.873,0,247.83-110.957,247.83-247.83S392.873,8.17,256,8.17z"
      ></path>
      <g>
        <path
          style={{ fill: "#F95FF2" }}
          d="M8.17,256c0,136.873,110.957,247.83,247.83,247.83V8.17C119.127,8.17,8.17,119.127,8.17,256z"
        ></path>
        <path
          style={{ fill: "#F95FF2" }}
          d="M258.353,40.873v430.254c117.553-1.253,212.796-97.28,212.796-215.127 S375.906,42.126,258.353,40.873z"
        ></path>
      </g>
      <path
        style={{ fill: "#FB8FF6" }}
        d="M258.353,40.873C357.888,42.365,438.468,138.294,438.468,256s-80.58,213.635-180.115,215.127 c-0.784,0.011-1.569,0.022-2.353,0.022c-118.631,0-215.149-96.517-215.149-215.149S137.368,40.851,256,40.851 C256.784,40.851,257.569,40.862,258.353,40.873z"
      ></path>
      <path
        style={{ fill: "#840C7F" }}
        d="M342.07,153.742c0,0,26.94,21.558,21.776,51.984c0,0-3.856,16.776-7.538,31.21l-59.449,24.112 L342.07,153.742z"
      ></path>
      <g>
        <path
          style={{ fill: "#AB15A4" }}
          d="M342.07,153.742l-16.035,94.437l0.076,0.207l-48.945,21.188l27.19-73.946 C309.531,165.202,342.07,153.742,342.07,153.742z"
        ></path>
        <path
          style={{ fill: "#AB15A4" }}
          d="M169.93,153.742l45.211,107.306l-59.449-24.112c-3.682-14.434-7.538-31.21-7.538-31.21 C142.99,175.3,169.93,153.742,169.93,153.742z"
        ></path>
      </g>
      <g>
        <path
          style={{ fill: "#840C7F" }}
          d="M234.834,269.573l-48.945-21.188l0.076-0.207l-16.035-94.437c0,0,32.539,11.46,37.714,41.886 L234.834,269.573z"
        ></path>
        <path
          style={{ fill: "#840C7F" }}
          d="M306.274,163.775c0,0-5.414,79.229-7.32,94.905l-64.741,16.275L256,84.426 C256,84.426,306.274,112.335,306.274,163.775z"
        ></path>
      </g>
      <path
        style={{ fill: "#AB15A4" }}
        d="M256,84.426v190.529l-42.954-16.275c-1.906-15.676-7.321-94.905-7.321-94.905 C205.726,112.335,256,84.426,256,84.426z"
      ></path>
      <path
        style={{ fill: "#D23ACB" }}
        d="M332.255,236.936c-40.029,0-59.521,27.123-76.255,27.123s-36.227-27.123-76.255-27.123h-76.255 v76.255c0,42.115,34.141,76.255,76.255,76.255c40.029,0,59.521-27.123,76.255-27.123s36.227,27.123,76.255,27.123 c42.115,0,76.255-34.141,76.255-76.255v-76.255H332.255z M186.449,337.663c-15.298,0-29.048-7.117-38.547-18.435 c9.499-11.319,23.248-18.435,38.547-18.435s29.048,7.117,38.547,18.435C215.496,330.546,201.747,337.663,186.449,337.663z M325.551,337.663c-15.298,0-29.048-7.117-38.547-18.435c9.499-11.319,23.248-18.435,38.547-18.435s29.048,7.117,38.547,18.435 C354.599,330.546,340.849,337.663,325.551,337.663z"
      ></path>
      <path d="M437.019,74.981C388.667,26.628,324.38,0,256,0S123.332,26.628,74.981,74.981C26.628,123.332,0,187.62,0,256 s26.628,132.668,74.981,181.019C123.332,485.372,187.62,512,256,512s132.667-26.628,181.019-74.981 C485.372,388.668,512,324.38,512,256S485.372,123.332,437.019,74.981z M256,495.66c-132.149,0-239.66-107.51-239.66-239.66 S123.851,16.34,256,16.34S495.66,123.851,495.66,256S388.149,495.66,256,495.66z"></path>
    </g>
  </svg>
                                <div className="currency-container">
                                  <span className="marker">DB</span>
                                  <span className="marker-subscript">Doubloon Coins</span>
                                </div>
                              </div>
                              <div className="value-container">
                                <span>${doubloons}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sticky">
                        <div className="flow-currency-btns">
                          <button
                            type="button"
                            className="currency-buttons deposit"
                            onClick={handleDepositClick} // Handle deposit click
                          >
                            Deposit Doubloons
                          </button>
                          <button
                            type="button"
                            className="currency-buttons withdraw"
                            onClick={handleWithdrawClick} // Handle withdraw click
                          >
                            Withdraw
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Wallet;