import React from "react";

const PrivacyPolicy = () => {
    return(
        <div>
        <header style={{ height: '60px', width: '100%', background: 'rgb(172 255 195)'}}>

        </header>
        <div style={{ padding: '1rem', color: '#fff', width: '1170px', marginLeft: 'auto', marginRight: 'auto' }}>
            <div style={{ marginBottom: '2rem' }}>
                <h1 style={{ fontSize: '2rem' }}>Privacy Policy</h1>
                <div style={{ fontSize: '12px'}}>Last Update Posted: March 25th, 2025</div>
            </div>
            <div style={{ gap: '1rem', display: 'flex', flexDirection: 'column' }}>
                <div>
                    <div style={{ fontSize: '1.5rem' }}>1. Introduction</div>
                    <div style={{ fontSize: '13px'}}>
                    1.1 GHChess ("we," "us," "our") recognizes that users of our chess tournament platform ("you" or "users") value their privacy. This Privacy Policy details important information regarding the collection, use, and disclosure of user information collected on the GHChess website and other services we offer (the "Services"). We provide this Privacy Policy to help you make an informed decision about whether to use or continue using the Services.
                    </div>
                    <div style={{ fontSize: '13px'}}>
                    1.2 This Privacy Policy should be read in conjunction with our Terms of Use. By accessing the Services, you are consenting to the information collection and use practices described in this Privacy Policy.
                    </div>
                    <div style={{ fontSize: '13px'}}>
                    1.3 Your use of the Services and any information you provide through the Services remains subject to the terms of this Privacy Policy and our Terms of Use, as each may be updated from time to time.
                    </div>
                    <div style={{ fontSize: '13px'}}>
                    1.4 Any questions, comments, or complaints regarding this Privacy Policy should be emailed to support@grandhustler.com.
                    </div>
                </div>

                <div>
                    <div style={{ fontSize: '1.5rem' }}>2. Information we collect</div>
                    <div  style={{ fontSize: '13px'}}>
                    By using our Services and by submitting information to us through your use of our Services, this Privacy Policy applies. You provide certain personal information to GHChess when choosing to participate in activities such as registering for an account, joining tournaments, posting messages, taking advantage of promotions, responding to surveys, or subscribing to newsletters or other mailing lists, or otherwise communicating with us or making requests. If you contact us, by email, phone call, or otherwise, we may record and store the contents of those communications ourselves or using a third-party vendor. We may use the contents of these communications for training or other purposes and to satisfy our legal, auditing, and other obligations, or for any reason set forth in this Privacy Policy. Your communications might include requests, questions, and/or issues regarding your use of the Services or other topics you might raise.
                    </div>
                    <div  style={{ fontSize: '13px'}}>
                    The personal information we collect from you generally may include:
                    </div>
                    <div  style={{ fontSize: '13px'}}>
                    2.1.1 your name
                    </div>
                    <div  style={{ fontSize: '13px'}}>
                    2.1.2 email address;
                    </div>
                    <div  style={{ fontSize: '13px'}}>
                    2.1.3 date of birth;
                    </div>
                    <div  style={{ fontSize: '13px'}}>
                    2.1.4 PayPal or credit card billing information;
                    </div>
                    <div  style={{ fontSize: '13px'}}>
                    2.1.5 contacts you choose to submit;
                    </div>
                    <div  style={{ fontSize: '13px'}}>
                    2.1.6 profile photo;
                    </div>
                    <div  style={{ fontSize: '13px'}}>
                    2.1.7 username;
                    </div>
                    <div  style={{ fontSize: '13px'}}>
                    2.1.8 location;
                    </div>
                    <div  style={{ fontSize: '13px'}}>
                    2.1.9 your preferences;
                    </div>
                    <div  style={{ fontSize: '13px'}}>
                    2.1.10 submissions to various competitions and promotions;
                    </div>
                    <div  style={{ fontSize: '13px'}}>
                    2.1.11 responses to surveys;
                    </div>
                    <div style={{ fontSize: '13px'}}>
                    2.1.12 communications sent to us by you;
                    </div>
                    <div style={{ fontSize: '13px'}}>
                    2.1.13 your subscriptions to newsletters and services offered by us; and
                    </div>
                    <div style={{ fontSize: '13px'}}>
                    2.1.14 any other information you submit when choosing to participate in various activities on the Services.
                    </div>
                    <div style={{ fontSize: '13px'}}>
                    2.2 In addition to the above, we may need to verify your identity in order for you to use some aspects of the Services. For purposes of verification, we may also collect the following personal information (for compliance reasons, the provision of this information, when requested, is a mandatory condition of using our Services):
                    </div>
                    <div style={{ fontSize: '13px'}}>
                    2.2.1 passport information;
                    </div>
                    <div style={{ fontSize: '13px'}}>
                    2.2.2 driving license information;
                    </div>
                    <div style={{ fontSize: '13px'}}>
                    2.2.3 other identification documents;
                    </div>
                    <div style={{ fontSize: '13px'}}>
                    2.2.4 permanent and temporary address;
                    </div>
                    <div style={{ fontSize: '13px'}}>
                    2.2.5 tax-related information; and
                    </div>
                    <div style={{ fontSize: '13px'}}>
                    2.2.6 other information as may be required to verify you in accordance with applicable laws and regulations.
                    </div>
                    <div style={{ fontSize: '13px'}}>
                    2.3 Cookies Information: When you visit the GHChess website, we may send one or more cookies — small files — to your computer or other device, which may enable us or others to uniquely identify your browser. GHChess uses both session cookies and persistent cookies. A persistent cookie remains after you close your browser. Persistent cookies may be used by your browser on subsequent visits to the Sites. Persistent cookies can be removed by following your web browser help file directions. Session cookies are temporary and typically disappear after you close your browser. You may be able to reset your web browser to refuse all cookies or to indicate when a cookie is being sent. However, some features of the Site or Service may not function properly if the ability to accept cookies is disabled.
                    </div>
                    <div style={{ fontSize: '13px'}}>
                    2.4 Log File Information: When you use our Service, our servers may automatically record certain information that your device sends whenever you visit any website and use certain apps. These server logs may include information such as your web or app request, Internet Protocol ("IP") address, browser type, browser language, referring/exit pages and URLs, platform type, number of clicks, domain names, landing pages, pages viewed and the order of those pages, the amount of time spent on particular pages, the date and time of your request, and one or more cookies that may uniquely identify your browser.
                    </div>
                </div>
                <div  style={{ fontSize: '13px'}}>
                    <div style={{ fontSize: '1.5rem' }}>3. The Way GHChess uses your personal information</div>
                    <div>
                    3.1 We use your personal information to operate, maintain, and provide you with the features and functionality of the Services, including but not limited to the following:</div>
                    <div>
                    3.1.1 providing you with our products and services, including our chess tournaments;</div>
                    <div>
                    3.1.2 processing and responding to enquiries;
                    </div>
                    <div>
                    3.1.3 personalizing your use of the Services;
                    </div>
                    <div>
                    3.1.4 alerting you to new features, special events, products and services, or certain third-party products or services in which we think you will be interested;</div>
                    <div>
                    3.1.5 enforcing the legal terms that govern your use of the Service; and
                    </div>
                    <div>
                    3.1.6 investigating and protecting the integrity of GHChess' tournaments.
                    </div>
                    <div>
                    3.2 We may use your information (both personal and non-personal information) to send you marketing and advertising content, including sending you advertising through multiple channels, such as direct mail, email, push notifications, display media, and personal text messages. We may send you advertising or content regarding our products and services, or products and services that we market on behalf of another company, such as chess organizations or other entities.
                    </div>
                    <div>
                    3.3 We may use your information to communicate with you about:
                    </div>
                    <div>
                    3.3.1 our products and services in which you may be interested, provided that you have not requested otherwise;
                    </div>
                    <div>
                    3.3.2 newsletters and information for which you have signed up; and</div>
                    <div>
                    3.3.3 non-marketing or administrative purposes (such as notifying you of major changes to the Service or for customer service purposes).
                    </div>
                    <div>
                    3.4 We use your information to improve the quality and design of our Service and to create new features, promotions, functionality, and services such as by storing, tracking, and analyzing user preferences and trends.
                    </div>
                    <div>
                    3.5 We also use cookies, clear gifs, log file information, and other information for purposes such as:
                    (a) remembering information so that you will not have to re-enter it during your visit or the next time you visit our Service;
                    (b) providing custom, personalized content and information;
                    (c) monitoring the effectiveness of our marketing campaigns;
                    (d) monitoring aggregate metrics such as total number of visitors, pages viewed, etc.; and
                    (e) tracking your entries, submissions, and status in promotions, sweepstakes, and tournaments.
                    </div>
                    <div>
                    3.6 We may anonymize, de-identify, or aggregate your information for any legitimate business purposes, including for reporting and research, or advertising. Such anonymized and/or aggregated data does not personally identify you.
                    </div>
                </div>

                <div style={{ fontSize: '13px'}}>
                    <div style={{ fontSize: '1.5rem' }}>4. When GHChess discloses information</div>
                    <div>
                    4.1 We may disclose the information we collect or receive from you to our subsidiaries, affiliated companies, agents, or other businesses, or service providers who process your information on our behalf in order to provide or operate the Service. These entities may have access to your information for purposes including billing and accounting, hosting, marketing, promotional and email services, customer and technical support, and for the purpose of accepting payments and processing transactions.</div>
                    <div>
                    4.2 When you choose to share information with social media services about your activities on GHChess, such as connecting your social media account to our Services, we may share your personal information and information about your use of the Services with such social media sites. Please make sure to read the terms and privacy statements of such sites prior to connecting to our Services in this way.</div>
                </div>

            </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy