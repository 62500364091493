import React from "react";
import GHChessInfo from "./GHChessInfo";
import '../css/footer.css'
const OwnershipFooter = () => {

    return(
        <div className="of-padding">
            <div>
                <div className="agepluslogo"></div>
            </div>
            <GHChessInfo />
            <div className="of-message">
                <div className="ta-c">Grandhustler is owned and operated by GHChess LLC 2024, for questions contact us at support@grandhustler.com.</div>
                <div className="ta-c">Persons under 18 are not permitted to engage in skilled wagering.</div>
              
            </div>
        </div>
    )
}

export default OwnershipFooter;