import React from 'react';
import { Link, useLocation  } from 'react-router-dom';
import NotificationBadge from './NotificationBadge';
import { useWebSocket } from '../contexts/WebSocketContext';

const SideBarMenu = ({ onOpenCreateGame, onToggleMessageTab }) => {
  const { notifications } = useWebSocket();
  const location = useLocation(); 

  // Helper to add 'bck-on' only if the route matches
  const isHomePage = location.pathname === '/';
  const isTournamentPage = location.pathname === '/tournament';
  
  return (
    <div>
       <div className={`${isHomePage ? 'color-tab' : isTournamentPage ? 'color-tab' : ''} ${isHomePage ? 'home' : isTournamentPage ? 'tournament-tab' : ''}`}></div>
      <div className="menu-group">
        
        <div>
          <div className="home-nav">
            <span className="home-nav-2">
              <div className="home-nav-3">
                <Link to="/" className="home-link" role="link" tabIndex="0">
                  <div className={`home-nav-4 ${location.pathname === '/' ? 'bck-on' : ''}`}>
                    <div>
                      <div className="home-nav-5">
                        <div className="home-logo-parent">
                          {isHomePage ? (
                            <svg aria-label="Home" className="home-logo" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="16">
                              <path d="M22 23h-6.001a1 1 0 0 1-1-1v-5.455a2.997 2.997 0 1 0-5.993 0V22a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V11.543a1.002 1.002 0 0 1 .31-.724l10-9.543a1.001 1.001 0 0 1 1.38 0l10 9.543a1.002 1.002 0 0 1 .31.724V22a1 1 0 0 1-1 1Z"></path>
                            </svg>
                          ) : (
                            <svg aria-label="Home" className="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="16">
                              <title>Home</title>
                              <path d="M9.005 16.545a2.997 2.997 0 0 1 2.997-2.997A2.997 2.997 0 0 1 15 16.545V22h7V11.543L12 2 2 11.543V22h7.005Z" fill="none" stroke="#fff" stroke-linejoin="round" stroke-width="2"></path>
                            </svg>
                          )}
                          <NotificationBadge count={notifications} />
                        </div>
                      </div>
                    </div>
                    <div className="home-nav-text-div">
                      <div style={{ width: '100%' }}>
                        <div style={{ width: '100%' }}>
                          <span className={`home-nav-text ${isHomePage ? 'chosen-tab' : ''}`}>
                            <span className="home-text">Home</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </span>
          </div>
        </div>

        <div>
          <span className="home-nav-2">
            <div className="home-nav-3">
              <Link to="/tournament" className="home-link" role="link" tabIndex="0">
                <div className={`home-nav-4 ${isTournamentPage ? 'bck-on' : ''}`}>
                  <div>
                    <div className="home-nav-5">
                      <div className="home-logo-parent">
                      {isTournamentPage ? (
                        <svg aria-label="Notifications" className="x1lliihq x1n2onr6 x5n08af" fill="rgb(172 255 195) " height="24" role="img" viewBox="0 0 24 24" width="16">
                          <title>View Tournaments</title>
                          <path d="M17.075 1.987a5.852 5.852 0 0 0-5.07 2.66l-.008.012-.01-.014a5.878 5.878 0 0 0-5.062-2.658A6.719 6.719 0 0 0 .5 8.952c0 3.514 2.581 5.757 5.077 7.927.302.262.607.527.91.797l1.089.973c2.112 1.89 3.149 2.813 3.642 3.133a1.438 1.438 0 0 0 1.564 0c.472-.306 1.334-1.07 3.755-3.234l.978-.874c.314-.28.631-.555.945-.827 2.478-2.15 5.04-4.372 5.04-7.895a6.719 6.719 0 0 0-6.425-6.965Z"></path>
                        </svg>
                      ) : (
                        <svg aria-label="Notifications" className="home-logo" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="16">
                          <title>View Tournaments</title>
                          <path d="M16.792 3.904A4.989 4.989 0 0 1 21.5 9.122c0 3.072-2.652 4.959-5.197 7.222-2.512 2.243-3.865 3.469-4.303 3.752-.477-.309-2.143-1.823-4.303-3.752C5.141 14.072 2.5 12.167 2.5 9.122a4.989 4.989 0 0 1 4.708-5.218 4.21 4.21 0 0 1 3.675 1.941c.84 1.175.98 1.763 1.12 1.763s.278-.588 1.11-1.766a4.17 4.17 0 0 1 3.679-1.938m0-2a6.04 6.04 0 0 0-4.797 2.127 6.052 6.052 0 0 0-4.787-2.127A6.985 6.985 0 0 0 .5 9.122c0 3.61 2.55 5.827 5.015 7.97.283.246.569.494.853.747l1.027.918a44.998 44.998 0 0 0 3.518 3.018 2 2 0 0 0 2.174 0 45.263 45.263 0 0 0 3.626-3.115l.922-.824c.293-.26.59-.519.885-.774 2.334-2.025 4.98-4.32 4.98-7.94a6.985 6.985 0 0 0-6.708-7.218Z"></path>
                        </svg>
                      )}
                      </div>
                    </div>
                  </div>
                  <div className="home-nav-text-div">
                    <div style={{ width: '100%' }}>
                      <div style={{ width: '100%' }}>
                        <span className={`home-nav-text ${isTournamentPage ? 'chosen-tab' : ''}`}>
                          <span className="home-text">Tournaments</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </span>
        </div>

        <div data-testid="tab_creategame_menu">
          <span className="home-nav-2">
            <div className="home-nav-3">
              <a className="home-link" role="link" tabIndex="0" onClick={onOpenCreateGame}>
                <div id="create-game" className="home-nav-4">
                  <div>
                    <div className="home-nav-5">
                      <div className="home-logo-parent">
                        <svg aria-label="New post" className="home-logo" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="16">
                          <title>Create Game</title>
                          <path d="M2 12v3.45c0 2.849.698 4.005 1.606 4.944.94.909 2.098 1.608 4.946 1.608h6.896c2.848 0 4.006-.7 4.946-1.608C21.302 19.455 22 18.3 22 15.45V8.552c0-2.849-.698-4.006-1.606-4.945C19.454 2.7 18.296 2 15.448 2H8.552c-2.848 0-4.006.699-4.946 1.607C2.698 4.547 2 5.703 2 8.552Z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></path>
                          <line fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" x1="12" x2="12" y1="8" y2="16"></line>
                          <line fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" x1="8" x2="16" y1="12" y2="12"></line>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="home-nav-text-div">
                    <div style={{ width: '100%' }}>
                      <div style={{ width: '100%' }}>
                        <span className="home-nav-text">
                          <span className="home-text">Create Game</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};

export default SideBarMenu;