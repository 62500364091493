import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { gql, useApolloClient, ApolloLink, ApolloClient } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const GET_ME = gql`
  query GetMe {
    me {
      id
      email
      username
      profilePic
      balance
    }
  }
`;

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const client = useApolloClient();

    const navigate = useNavigate();
    const location = useLocation();

  useEffect(() => {
    const fetchUser = async () => {
      const token = localStorage.getItem('token');
      
      if (!token && location.pathname !== '/register' &&  location.pathname !== '/name' && location.pathname !== '/login') {
        navigate('/login');
      }

      const authLink = setContext(() => ({
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      }));

      const authClient = new ApolloClient({
        link: ApolloLink.from([authLink, client.link]),
        cache: client.cache,
      });

      const { data } = await authClient.query({
        query: GET_ME,
        fetchPolicy: 'network-only', // Ensure it fetches from the server
      });

      setUser(data.me);
    };

    fetchUser();
  }, [client]);

  return (
    <UserContext.Provider value={user}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};