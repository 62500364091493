import React, { useState, useEffect } from 'react';
import FlagIcons from './FlagIcons';

const Matches = () => {
  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [games, setGames] = useState([]);

  const formatDate = (date) => {
    return date.toISOString().split('T')[0];
  };

  useEffect(() => {
    const today = new Date();
    const newDates = Array.from({ length: 7 }, (_, i) => {
      const date = new Date(today);
      date.setDate(today.getDate() + i - 3);
      return {
        day: date.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase(),
        date: formatDate(date),
        isToday: i === 3,
      };
    });

    setDates(newDates);
    const todayFormatted = formatDate(today);
    setSelectedDate(todayFormatted);
    fetchScoreboard(todayFormatted);
  }, []);

  const fetchScoreboard = async (date) => {
    const token = localStorage.getItem('token'); 
    if (!token) {
      console.error("No token found in localStorage");
      return;
    }

    try {
      const response = await fetch('https://grandhustler.com/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          query: `
            query GetDaysScoreboard($date: String!) {
              getDaysScoreboard(date: $date) {
                day
                date
                player1
                player1_rank
                player1_score
                player2
                player2_rank
                player2_score
                finished
                player1_nationality
                player2_nationality
              }
            }
          `,
          variables: { date },
        }),
      });

      const result = await response.json();
      if (response.ok && result.data) {
        setGames(result.data.getDaysScoreboard);
      } else {
        console.error("Error fetching scoreboard:", result.errors || result);
      }
    } catch (error) {
      console.error("Error fetching scoreboard:", error);
    }
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
    fetchScoreboard(date);
  };

  const renderFlag = (nationality) => {
    if (!nationality) return null;
    const FlagIcon = FlagIcons[nationality];

    return (
      <div className="country-flag">
        { FlagIcon }
      </div>
    );
  };

  return (
    <div style={{ width: '100%', marginTop: '1.5rem', padding: '1rem 1rem 1rem 0px', }}>
      <span style={{ color: '#fff' }}>Scoreboard</span>
      <div style={{ background: 'rgb(30, 30, 30)', borderRadius: '8px', border: '1px solid rgb(118, 118, 118)', padding: '20px', margin: '1rem auto 0px'}}>
      {/* Dates Section */}
      <div className="dates" style={{ gap: '0.6rem'}}>
        {dates.map((item, index) => (
          <div
            key={index}
            className={`pair ${item.isToday ? selectedDate === null ? 'today' : 'not-today' : ''} ${
              selectedDate === item.date ? 'today' : 'not-today'
            }`}
            onClick={() => handleDateClick(item.date)}
          >
            <div className="day">{item.day}</div>
            <div className="date" data-value={item.date}>
              {item.date}
            </div>
          </div>
        ))}
      </div>

      <div className="games" style={{ height: '193px' }}>
        {games.length > 0 ? (
          // Iterate over games, but step by 2 to pair them in a single row
          Array.from({ length: Math.ceil(games.length / 2) }).map((_, index) => {
            const game1 = games[index * 2]; // First game in the pair
            const game2 = games[index * 2 + 1]; // Second game in the pair (if exists)

            return (
              <div key={index} className="row">
                {/* First Game in the Row */}
                <div className={`one ${game1.finished === true ? 'finished' : ''}`}>
                  <div className={`player one ${game1.player1_score > game1.player2_score ? 'won' : ''}`}>
                    {renderFlag(game1.player1_nationality)}
                    <div className="ranking">{game1.player1_rank > 0 ? game1.player1_rank : ''}</div>
                    <div className="name">{game1.player1}</div>
                    <div className="score">{game1.player1_score}</div>
                  </div>
                  <div className={`player two ${game1.player2_score > game1.player1_score ? 'won' : ''}`}>
                    {renderFlag(game1.player2_nationality)}
                    <div className="ranking">{game1.player2_rank > 0 ? game1.player2_rank : ''}</div>
                    <div className="name">{game1.player2}</div>
                    <div className="score">{game1.player2_score}</div>
                  </div>
                </div>

                {/* Second Game in the Row */}
                {game2 && (
                  <div className={`two ${game1.finished === true ? 'finished' : ''}`}>
                    <div className={`player one ${game2.player1_score > game2.player2_score ? 'won' : ''}`}>
                      {renderFlag(game2.player1_nationality)}
                      <div className="ranking">{game2.player1_rank > 0 ? game2.player1_rank : ''}</div>
                      <div className="name">{game2.player1}</div>
                      <div className="score">{game2.player1_score}</div>
                    </div>
                    <div className={`player two ${game2.player2_score > game2.player1_score ? 'won' : ''}`}>
                      {renderFlag(game2.player2_nationality)}
                      <div className="ranking">{game2.player2_rank > 0 ? game2.player2_rank : ''}</div>
                      <div className="name">{game2.player2}</div>
                      <div className="score">{game2.player2_score}</div>
                    </div>
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <div className="center white p3">No games to display for the selected date.</div>
        )}
      </div>
      </div>

    </div>
  );
};

export default Matches;



