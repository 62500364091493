import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Register Chart.js modules
ChartJS.register(ArcElement, Tooltip, Legend);

const FinancialDonutChart = ({ winPercentage, lossPercentage, drawPercentage }) => {
  let unplayed = 0;
  if(winPercentage === 0 && lossPercentage === 0 && drawPercentage === 0){
    unplayed = 100;
  }
  const data = {
    datasets: [
      {
        data: [winPercentage, lossPercentage, drawPercentage, unplayed],
        backgroundColor: [
          "rgb(172, 255, 195)", // Win - light green
          "#ed4163",             // Loss - red
          "rgb(0, 161, 194)",             // Draw - greyish blue
          "orange"
        ],
        borderWidth: 0, // Removes borders between segments
      },
    ],
  };

  // Options for the chart
  const options = {
    cutout: "70%", // Creates the donut shape by cutting out the center
    responsive: true,
    plugins: {
      tooltip: {
        enabled: false, // Disable tooltips
      },
      legend: {
        display: false, // Hide legend
      },
    },
  };

  return (
    <div style={{ padding: '1rem', color: '#fff', paddingLeft: '0', width: '100%'}}>
        <span>Evaluation</span>
        <div style={{ margin: '0px auto', display: 'flex', marginTop: '1rem', background: 'rgb(30, 30, 30)', borderRadius: '8px', padding: '1.2rem', border: '1px solid rgb(118,118,118)' }}>
    <div style={{ position: "relative", width: "171px", marginRight: '20px', alignItems: 'center', display: 'flex'}}>
      {/* Donut Chart */}
      <Doughnut data={data} options={options} />

      {/* Center Text */}
      <div
        style={{
          position: "absolute",
          top: "45%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          fontSize: "40px",
          fontWeight: "bold",
          color: "#ffffff",
        }}
      >
        <span style={{ minWidth: '105px' }}>{winPercentage}%</span>
      </div>
      </div>

      {/* Legend and Percentages Underneath */}
      <div style={{ textAlign: "center", minWidth: '250px' }}>
        {/* Win */}
        <div style={{ display: 'flex', alignItems: 'center', flex: '1', color: '#fff', borderBottom: '0.5px solid #fff', padding: '1rem', marginBottom: '5px'}}>
          <div style={{ display: 'flex', alignItems: 'center', flex: '1'}}>
          <div
            style={{
              width: "12px",
              height: "12px",
              backgroundColor: "rgb(172, 255, 195)", // Win - light green
              marginRight: "10px",
              borderRadius: '2px'
            }}
          ></div>
          <div style={{ fontWeight: '400' }}>Wins</div>
          </div>
          <span>{winPercentage}%</span>
        </div>

        {/* Loss */}
        <div style={{ display: 'flex', alignItems: 'center', flex: '1', color: '#fff', borderBottom: '0.5px solid #fff', padding: '1rem', marginBottom: '5px'}}>
        <div style={{ display: 'flex', alignItems: 'center', flex: '1'}}>
            <div
                style={{
                width: "12px",
                height: "12px",
                backgroundColor: "#ed4163", // Loss - red
                marginRight: "10px",
                borderRadius: '2px'
                }}
            ></div>
            <div style={{ fontWeight: '400' }}>Losses</div>
          </div>
          <span>{lossPercentage}%</span>
        </div>

        {/* Draw */}
        <div style={{ display: 'flex', alignItems: 'center', flex: '1', color: '#fff', borderBottom: '0.5px solid #fff', padding: '1rem', marginBottom: '5px', justifyContent: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center', flex: '1'}}>
          <div
            style={{
              width: "12px",
              height: "12px",
              backgroundColor: "rgb(0, 161, 194)", // Draw - greyish blue
              marginRight: "10px",
              borderRadius: '2px'
            }}
          ></div>
          <div style={{ fontWeight: '400' }}>Draws</div>
          </div>
          <span>{drawPercentage}%</span>
        </div>

        <div style={{ display: 'flex', alignItems: 'center', flex: '1', color: '#fff', borderBottom: '0.5px solid #fff', padding: '1rem', marginBottom: '5px'}}>
            <div style={{ display: 'flex', alignItems: 'center', flex: '1'}}>
          <div
            style={{
              width: "12px",
              height: "12px",
              backgroundColor: "orange", // Draw - greyish blue
              marginRight: "10px",
              borderRadius: '2px'
            }}
          ></div>
          <div style={{ fontWeight: '400' }}>Unplayed</div>
          </div>
          <span>{unplayed}%</span>
        </div>
      </div>
    </div>
    </div>
  );
};

export default FinancialDonutChart;
