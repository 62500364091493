import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { LOGIN_MUTATION } from '../mutations';
import EmailInput from './EmailInput';
import PasswordInput from './PasswordInput';
import { useNavigate } from 'react-router-dom';

const LoginForm = ({ onShowSignUp }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [login, { loading }] = useMutation(LOGIN_MUTATION);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setError(''); // Reset error state before submitting

    try {
      const { data } = await login({ variables: { email, password } });

      if (data?.login) {
        localStorage.setItem('token', data.login);
        window.location.href = '/';
      }
    } catch (err) {
      // Capture the server error message and display it
      if (err.graphQLErrors?.length > 0) {
        setError(err.graphQLErrors[0].message);
      } else {
        setError('An unexpected error occurred. Please try again later.');
      }
    }
  };

  return (
    <form
      id="loginForm"
      onSubmit={handleSubmit}
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="false"
      style={{ minWidth: 380 }}
    >
      <EmailInput email={email} setEmail={setEmail} />
      <PasswordInput password={password} setPassword={setPassword} />

      {error && <div className="error" style={{ fontSize: '12px' }}>{error}</div>}

      <div className="gh-17w8">
        <button id="login-submit" role="button" type="submit" className="gh-w4pc" disabled={loading}>
          <div className="gh-141e">Log In</div>
        </button>
      </div>
    </form>
  );
};

export default LoginForm;
