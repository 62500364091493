import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./SideBar";
import ClassicGrid from "./ClassicGrid";
import BulletGrid from "./BulletGrid";
import BlitzGrid from "./BlitzGrid";
import PhysicalGrid from "./PhysicalGrid";
import Footer from "./Footer";
import OwnershipFooter from "./OwnershipFooter";
import Modal from "./CreateGameModal";

const TournamentLayout = () => {
  const [showModal, setShowModal] = useState(false);
  const [showTourneyModal, setShowTourneyModal] = useState(false);
  const [activeGrid, setActiveGrid] = useState("classic");
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false); // State for collapsing the sidebar

  //CREATE GAME MODAL FUNCTIONS
    //open
    const handleOpenTourneyCreateGame = () => {
      setShowTourneyModal(true);
    };
    const handleOpenCreateGame = () => {
      setShowModal(true);
    };
    //submit
    const handleSubmitModal = () => {
      setShowModal(false);
      setShowTourneyModal(false)
    };
    //close
    const closeCreateGame = () => {
      setShowModal(false);
    };
    const closeTourneyCreateGame = () => {
      setShowTourneyModal(false);
    };
  //

  const toggleSidebar = () => {
    setIsSidebarCollapsed((prevState) => !prevState); // Toggle the collapsed state
  };

  const renderActiveGrid = () => {
    switch (activeGrid) {
      case "classic":
        return <ClassicGrid open={handleOpenTourneyCreateGame} />;
      case "bullet":
        return <BulletGrid open={handleOpenTourneyCreateGame} />;
      case "blitz":
        return <BlitzGrid open={handleOpenTourneyCreateGame}/>;
      case "physical":
        return <PhysicalGrid />;
      default:
        return <ClassicGrid open={handleOpenTourneyCreateGame} />;
    }
  };

  useEffect(() => {
    const mediaHolder = document.querySelector('.media-holder');
    const chessbookWrapper = document.querySelector('.chessbook-wrapper');

    const checkWidth = () => {
      if (window.innerWidth < 1330) {
        if (mediaHolder && chessbookWrapper && !chessbookWrapper.parentNode.isEqualNode(mediaHolder.parentNode)) {
          mediaHolder.parentNode.insertBefore(chessbookWrapper, mediaHolder);
        }
      } else {
        if (mediaHolder && chessbookWrapper && !mediaHolder.contains(chessbookWrapper)) {
          mediaHolder.appendChild(chessbookWrapper);
        }
      }
    };

    checkWidth(); // Initial check on mount

    window.addEventListener('resize', checkWidth);

    return () => {
      window.removeEventListener('resize', checkWidth); // Cleanup listener on unmount
    };
  }, []);

  return (
    <div>
      <Header onToggleSidebar={toggleSidebar} /> {/* Pass the toggle function to Header */}
      <div className="amaqao">
        <Sidebar 
          isCollapsed={isSidebarCollapsed}  // Pass the collapse state to Sidebar
          onOpenCreateGame={handleOpenCreateGame}
        />
        <div className="flex fd-c tournament-margin">
        <div className={`tournament-fixings ${isSidebarCollapsed ? "expanded" : ""}`}> {/* Adjust layout based on collapse state */}
          <div className="tournament">
            <div className="type-nav">
              <div className="nav-layout">
                <ul className="tab-list">
                  <li
                    className={`tab-item ${activeGrid === "classic" ? "tabs-list-item--active" : ""}`}
                    onClick={() => setActiveGrid("classic")}
                  >
                    <a href="#">Classic</a>
                  </li>
                  <li
                    className={`tab-item ${activeGrid === "bullet" ? "tabs-list-item--active" : ""}`}
                    onClick={() => setActiveGrid("bullet")}
                  >
                    <a href="#">Bullet</a>
                  </li>
                  <li
                    className={`tab-item ${activeGrid === "blitz" ? "tabs-list-item--active" : ""}`}
                    onClick={() => setActiveGrid("blitz")}
                  >
                    <a href="#">Blitz</a>
                  </li>
                  <li
                    className={`tab-item ${activeGrid === "physical" ? "tabs-list-item--active" : ""}`}
                    onClick={() => setActiveGrid("physical")}
                  >
                    <a href="#">Physical</a>
                  </li>
                </ul>
              </div>
            </div>
            {renderActiveGrid()}
          </div>
          
        </div>
        <OwnershipFooter />
        <Footer />
        </div>
      </div>
      <Modal show={showModal} onClose={closeCreateGame} onSubmit={handleSubmitModal} />
      <Modal show={showTourneyModal} onClose={closeTourneyCreateGame} onSubmit={handleSubmitModal} section='group' />
    </div>
  );
};

export default TournamentLayout;